import { actionTypes } from '@morawadigital/skynet-framework'
import { SET_UI_VALUE } from './constants'

const initialStateUi = {

    clubFilterPersons:                [],
    clubTypeFilterClubs:              null,
    context:                          null,
    federationFilterClubs:            null,
    playerLicenseRequestStatusFilter: null,
    searchHistory:                    [],
    textFilterClubs:                  '',
    textFilterPersons:                '',
    textFilterPlayerLicenseRequests:  '',
    textFilterTransferRequests:       '',
    transferRequestStatusFilter:      null,

}

export const ui = ( state = initialStateUi, action ) => {

    switch ( action.type ) {

        case actionTypes.LOGOUT:
        case actionTypes.RESET_APP: {

            return initialStateUi

        }

        case SET_UI_VALUE: {

            return { ...state, [ action.payload.key ]: action.payload.value }

        }

        default:

            return state

    }

}