import React from 'react'
import { ErrorBoundary, Icon, Login, MenuItem, Skynet, createDate, hasPrivilege, reloadPage, translate as _ } from '@morawadigital/skynet-framework'
import { Route } from 'react-router-dom'
import packageJson from '../../../package.json'
import { isDevelopment } from '../util'
import { Button, Toast, ToastContainer } from 'react-bootstrap'
import { PRIV_LOAD_AGEGROUPS, PRIV_LOAD_CLUBS, PRIV_LOAD_FEDERATIONS, PRIV_LOAD_NATIONS, PRIV_LOAD_PERSONS, PRIV_LOAD_PLAYER_LICENSE_REQUESTS, PRIV_LOAD_TRANSFER_REQUESTS } from '../util/constants'
import { getCustomer } from '../util/customer'
import Dashboard from '../containers/pages/Dashboard'
import SearchPage from './pages/Search'
import Clubs from '../containers/pages/Clubs'
import Club from '../containers/pages/Club'
import Persons from '../containers/pages/Persons'
import Person from './pages/Person'
import Search from '../containers/controls/Search'
import AgeGroups from '../containers/pages/AgeGroups'
import AgeGroup from './pages/AgeGroup'
import Federations from '../containers/pages/Federations'
import Federation from './pages/Federation'
import Nations from '../containers/pages/Nations'
import Nation from './pages/Nation'
import Context from '../containers/controls/Context'
import PlayerLicenseRequests from '../containers/pages/PlayerLicenseRequests'
import TransferRequests from '../containers/pages/TransferRequests'

export default class App extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            appUpdateAvailable: false,
            updatingApp:        false,

        }

    }

    componentDidMount() {

        setTimeout( () => {

            if ( window.appUpdateAvailable ) {

                this.setState( { appUpdateAvailable: true } )

                setTimeout( () => this.setState( { appUpdateAvailable: false } ), 1000 * 60 )

            }

        }, 1000 * 5 )

    }

    componentDidUpdate() {

        if ( this.props.user ) {

            if ( ! this.props.context && this.props.contexts.length ) {

                this.props.onSetContext( this.props.contexts[ 0 ] )

            } else if ( ! this.props.contexts.find( e => e.value === this.props.context.value ) ) {

                this.props.onSetContext( null )

            }

        }

    }

    hideAppUpdateNotification() {

        this.setState( { appUpdateAvailable: false } )

    }

    updateApp() {

        this.setState( { updatingApp: true }, () => {

            caches
                .keys()
                .then( keys => keys.forEach( c => caches.delete( c ) ) )
                .then( () => setTimeout( reloadPage, 1000 * 3 ) )

        } )

    }

    render() {

        const customer = getCustomer()
        let baseDataUrl

        if ( process.env.REACT_APP_BASE_DATA_URL ) {

            baseDataUrl = process.env.REACT_APP_BASE_DATA_URL

            if ( process.env.REACT_APP_BASE_DATA_PORT ) {

                baseDataUrl += ':' + process.env.REACT_APP_BASE_DATA_PORT + '/'

            }

        } else if ( isDevelopment() ) {

            baseDataUrl = customer.devBaseDataUrl

        } else {

            baseDataUrl = customer.baseDataUrl[ window.location.hostname ]

        }

        const conf ={

            firebaseConfig:              {

                apiKey:            'AIzaSyDSQoHoe0xzOF_MC-ildFewcc3z2GZzDGU',
                authDomain:        'morawa-skynet-push.firebaseapp.com',
                projectId:         'morawa-skynet-push',
                storageBucket:     'morawa-skynet-push.appspot.com',
                messagingSenderId: '721850682646',
                appId:             '1:721850682646:web:719b96d6ce195b36f9d437',
                measurementId:     'G-J9SFWPY19K',

            },
            footerLeft:                  createDate().getFullYear() + ' © Data in Motion',
            footerRight:                 'myFederation v' + packageJson.version,
            logoImageUrl:                customer.logoImageUrl,
            logoText:                    'myFederation',
            portalHeaderCenter:          <><Context /><Search /></>,

            baseDataUrl,

        }

        let mainNavigationMenuItems = []
        let pageRoutes              = []

        if ( this.props.context ) {

            if ( this.props.context.club ) {

                if ( hasPrivilege( this.props.user, PRIV_LOAD_CLUBS ) ) {

                    mainNavigationMenuItems.push( { url: _( '/verein' ), children: <><Icon fw icon='flag' /><span className='nav-link-text'>{ this.props.context.club.name }</span></> } )

                    pageRoutes.push( { path: [ '/club/:id', '/verein/:id' ], children: <Club /> } )

                }

                if ( hasPrivilege( this.props.user, PRIV_LOAD_PERSONS ) ) {

                    mainNavigationMenuItems.push( { url: _( '/personen' ), children: <><Icon fw icon='users' /><span className='nav-link-text'>{ _( 'Personen' ) }</span></> } )

                    pageRoutes.push( { path: [ '/person/:id'             ], children: <Person loading={ this.props.loading } /> } )
                    pageRoutes.push( { path: [ '/persons',   '/personen' ], children: <Persons /> } )

                }

                if ( hasPrivilege( this.props.user, PRIV_LOAD_PLAYER_LICENSE_REQUESTS ) ) {

                    mainNavigationMenuItems.push( { url: _( '/spielerlizenzen' ), children: <><Icon fw icon='id-card' /><span className='nav-link-text'>{ _( 'Spielerlizenzen' ) }</span></> } )

                    pageRoutes.push( { path: [ '/player-licenses', '/spielerlizenzen' ], children: <PlayerLicenseRequests /> } )

                }

                if ( hasPrivilege( this.props.user, PRIV_LOAD_TRANSFER_REQUESTS ) ) {

                    mainNavigationMenuItems.push( { url: _( '/antraege' ), children: <><Icon fw icon='code-pull-request' /><span className='nav-link-text'>{ _( 'Anträge' ) }</span></> } )

                    pageRoutes.push( { path: [ '/requests', '/antraege' ], children: <TransferRequests /> } )

                }

            } else if ( this.props.context.federation ) {

                mainNavigationMenuItems.push( { url: '/dashboard', children: <><Icon fw icon='dashboard' /><span className='nav-link-text'>{ _( 'Dashboard' ) }</span></> } )

                pageRoutes.push( { path: [ '/dashboard' ], children: <Dashboard /> } )

                if ( hasPrivilege( this.props.user, PRIV_LOAD_FEDERATIONS ) ) {

                    mainNavigationMenuItems.push( { url: _( '/verbaende' ), children: <><Icon fw icon='cubes' /><span className='nav-link-text'>{ _( 'Verbände' ) }</span></> } )

                    pageRoutes.push( { path: [ '/federation/:id', '/verband/:id' ], children: <Federation loading={ this.props.loading } /> } )
                    pageRoutes.push( { path: [ '/federations',    '/verbaende'   ], children: <Federations /> } )

                }

                if ( hasPrivilege( this.props.user, PRIV_LOAD_CLUBS ) ) {

                    mainNavigationMenuItems.push( { url: _( '/vereine' ), children: <><Icon fw icon='flag' /><span className='nav-link-text'>{ _( 'Vereine' ) }</span></> } )

                    pageRoutes.push( { path: [ '/club/:id', '/verein/:id' ], children: <Club /> } )
                    pageRoutes.push( { path: [ '/clubs',    '/vereine'    ], children: <Clubs /> } )

                }

                if ( hasPrivilege( this.props.user, PRIV_LOAD_PERSONS ) ) {

                    mainNavigationMenuItems.push( { url: _( '/personen' ), children: <><Icon fw icon='users' /><span className='nav-link-text'>{ _( 'Personen' ) }</span></> } )

                    pageRoutes.push( { path: [ '/person/:id'             ], children: <Person loading={ this.props.loading } /> } )
                    pageRoutes.push( { path: [ '/persons',   '/personen' ], children: <Persons /> } )

                }

                if ( hasPrivilege( this.props.user, PRIV_LOAD_PLAYER_LICENSE_REQUESTS ) ) {

                    mainNavigationMenuItems.push( { url: _( '/spielerlizenzen' ), children: <><Icon fw icon='id-card' /><span className='nav-link-text'>{ _( 'Spielerlizenzen' ) }</span></> } )

                    pageRoutes.push( { path: [ '/player-licenses', '/spielerlizenzen' ], children: <PlayerLicenseRequests /> } )

                }

                if ( hasPrivilege( this.props.user, PRIV_LOAD_TRANSFER_REQUESTS ) ) {

                    mainNavigationMenuItems.push( { url: _( '/antraege' ), children: <><Icon fw icon='code-pull-request' /><span className='nav-link-text'>{ _( 'Anträge' ) }</span></> } )

                    pageRoutes.push( { path: [ '/requests', '/antraege' ], children: <TransferRequests /> } )

                }

            }

        }

        pageRoutes.push( { path: [ '/search/:query', '/suche/:query', '/search', '/suche' ], children: <SearchPage /> } )

        if ( hasPrivilege( this.props.user, PRIV_LOAD_AGEGROUPS ) ) {

            // TODO Enable
            // mainNavigationMenuItems.push( { url: _( '/altersklassen' ), children: <><Icon fw icon='people-group' /><span className='nav-link-text'>{ _( 'Altersklassen' ) }</span></> } )

            pageRoutes.push( { path: [ '/agegroup/:id', '/altersklasse/:id' ], children: <AgeGroup loading={ this.props.loading } /> } )
            pageRoutes.push( { path: [ '/agegroups',    '/altersklassen'    ], children: <AgeGroups /> } )

        }

        if ( hasPrivilege( this.props.user, PRIV_LOAD_NATIONS ) ) {

            // TODO Enable
            // mainNavigationMenuItems.push( { url: _( '/nationen' ), children: <><Icon fw icon='earth-americas' /><span className='nav-link-text'>{ _( 'Nationen' ) }</span></> } )

            pageRoutes.push( { path: [ '/nation/:id'             ], children: <Nation loading={ this.props.loading } /> } )
            pageRoutes.push( { path: [ '/nations',   '/nationen' ], children: <Nations /> } )

        }

        if ( mainNavigationMenuItems.length ) {

            mainNavigationMenuItems[ 0 ].url = '/'

        }

        if ( pageRoutes.length ) {

            const firstPageRoute = pageRoutes.shift()
            firstPageRoute.path  = '/'

            pageRoutes.push( firstPageRoute )

        }

        mainNavigationMenuItems = mainNavigationMenuItems.map( ( e, i ) => <MenuItem url={ e.url } key={ i }>{ e.children }</MenuItem> )
        pageRoutes              = pageRoutes.map( ( e, i ) => <Route path={ e.path } key={ i } children={ e.children } /> )

        conf.mainNavigationMenuItems = mainNavigationMenuItems
        conf.pageRoutes              = pageRoutes
        conf.rootComponent           = <Login logoImageUrl={ conf.logoImageUrl } logoText={ conf.logoText } />

        return (

            <ErrorBoundary>

                <Skynet { ...conf } />

                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast show={ this.state.appUpdateAvailable } onClose={ () => this.hideAppUpdateNotification() }>

                        <Toast.Header>

                            <div className='flex-grow-1'>{ _( 'Update verfügbar' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Eine neue App-Version ist verfügbar!' ) }</p>

                            <Button onClick={ () => this.updateApp() } disabled={ this.state.updatingApp }>{ this.state.updatingApp ? _( 'Installiere...' ) : _( 'Jetzt installieren' ) }</Button>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

            </ErrorBoundary>

        )

    }

}