import Select from 'react-select'
import { Col, Form, Row } from 'react-bootstrap'
import { translate as _ } from '@morawadigital/skynet-framework'
import ItemsPage from './ItemsPage'
import PlayerLicenseRequest from '../items/PlayerLicenseRequest'
import { findTextInObject, getFullName, getPlayerLicenseRequestStatuses, updateItem } from '../../util'

export default class PlayerLicenseRequests extends ItemsPage {

    constructor( props ) {

        super( props )

        this.itemComponent = PlayerLicenseRequest
        this.pageIcon      = 'id-card'
        this.pageTitle     = _( 'Spielerlizenzen' )

    }

    checkItem( item ) {

        if ( this.props.textFilter && ! findTextInObject( item, this.props.textFilter, [ 'clubName', 'personName', 'playerLicenseTypeName' ] ) ) {

            return false

        }

        if ( this.props.statusFilter && this.props.statusFilter.length && ! this.props.statusFilter.find( e => e.value === item.Status ) ) {

            return false

        }

        return true

    }

    convertItems( items ) {

        return items.map( e => ( {

            clubName:              e.Club              ? e.Club.Name                    : '',
            personName:            e.Player            ? getFullName( e.Player.Person ) : '',
            playerLicenseTypeName: e.PlayerLicenseType ? e.PlayerLicenseType.Name       : '',

            ...e

        } ) )

    }

    getItemProps() {

        return {

            onUpdate:    e => this.setState( { items: updateItem( this.state.items, e ) } ),
            respondable: this.props.context && this.props.context.federation,
            showPerson:  true,

        }

    }

    getDataParams() {

        const params = {}

        if ( this.props.context ) {

            if ( this.props.context.federation ) { params.fedId  = this.props.context.federation.id }
            if ( this.props.context.club       ) { params.clubId = this.props.context.club.id       }

        }

        return params

    }

    getDataUrl() {

        return 'api/PlayerLicenseRequest/Get'

    }

    renderFilters() {

        return (

            <Form className='sticky-filters'>

                <Row>

                    <Col>

                        <Form.Control
                            onChange={ e => this.props.onUiValueChange( 'textFilterPlayerLicenseRequests', e.target.value ) }
                            placeholder={ _( 'Spielerlizenzen filtern...' ) }
                            value={ this.props.textFilter }
                        />

                    </Col>

                    <Col>

                        <Select
                            isClearable={ true }
                            isMulti={ true }
                            noOptionsMessage={ () => _( 'Keine Optionen verfügbar' ) }
                            onChange={ e => this.props.onUiValueChange( 'playerLicenseRequestStatusFilter', e ) }
                            options={ getPlayerLicenseRequestStatuses() }
                            placeholder={ _( 'Status' ) }
                            value={ this.props.statusFilter }
                        />

                    </Col>

                </Row>

            </Form>

        )

    }

}