import React from 'react'
import { Form, Nav, Tab } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import { Icon, ajxs, translate as _, sortByKey } from '@morawadigital/skynet-framework'
import { getSearchFields } from '../../util/search'
import { findTextInObject, getFullName, getGenders, splitRange } from '../../util'
import Club from '../../containers/items/Club'
import Person from '../items/Person'

class Search extends React.Component {

    #data
    #fields  = getSearchFields()
    #genders = getGenders()

    #checkKeys = {

        'club':   [ 'Name'                  ],
        'person': [ 'Lastname', 'Firstname' ],

    }

    constructor( props ) {

        super( props )

        this.state = {

            activeTab:  'all',
            clubs:      [],
            loading:    false,
            query:      this.props.match.params.query,
            persons:    [],
            textFilter: '',

        }

    }

    componentDidMount() {

        this.load()

    }

    componentDidUpdate() {

        if ( this.props.match.params.query !== this.state.query ) {

            this.setState( { activeTab: 'all', clubs: [], persons: [], query: this.props.match.params.query }, () => this.load() )

        }

    }

    checkItem( item ) {

        if ( this.state.textFilter && ! findTextInObject( item, this.state.textFilter, this.isPerson( item ) ? this.#checkKeys.person : this.#checkKeys.club ) ) {

            return false

        }

        return true

    }

    isPerson( e ) {

        return 'Lastname' in e

    }

    load() {

        if ( ! this.state.query ) {

            return

        }

        this.parseQuery()

        this.setState( { loading: true }, () => {

            const items = []

            if ( Object.keys( this.#data.club   ).length ) { items.push( { data: this.#data.club,   options: { method: 'GET' }, success: clubs   => this.setState( { clubs   } ), url: 'api/Search/searchClub'   } ) }
            if ( Object.keys( this.#data.person ).length ) { items.push( { data: this.#data.person, options: { method: 'GET' }, success: persons => this.setPersons( persons   ), url: 'api/Search/searchPerson' } ) }

            ajxs( {

                complete:      () => this.setState( { loading: false } ),
                toggleLoading: true,

            }, items )

        } )

    }

    parseQuery() {

        this.#data  = { club: {}, person: {} }
        const parts = this.state.query.toLowerCase().match( /(".*?"|[^"\s]+)+(?=\s*|\s*$)/g )

        parts.forEach( part => {

            const partSplit = part.split( ':' )

            if ( partSplit.length === 2 ) {

                const field = this.#fields.find( e => e.keyWord === partSplit[ 0 ] )

                if ( field ) {

                    let value = partSplit[ 1 ].replace( /"/g, '' )

                    if ( field.param === 'yearFromTo' ) {

                        value = splitRange( value )

                        this.#data[ field.group ][ 'yearFrom' ] = value.from
                        this.#data[ field.group ][ 'yearTo'   ] = value.to

                    } else if ( field.param === 'gender' ) {

                        const gender = this.#genders.find( e => e.label === value )

                        if ( gender ) {

                            this.#data[ field.group ][ field.param ] = gender.value

                        }

                    } else {

                        this.#data[ field.group ][ field.param ] = value

                    }

                }

            } else {

                if ( 'searchText' in this.#data.club   ) { this.#data.club.searchText   += ' ' + part } else { this.#data.club.searchText   = part }
                if ( 'searchText' in this.#data.person ) { this.#data.person.searchText += ' ' + part } else { this.#data.person.searchText = part }

            }

        } )

    }

    setPersons( persons ) {

        this.setState( { persons: persons.map( e => ( { ...e, Name: getFullName( e ) } ) ) } )

    }

    renderItem( item, i ) {

        if ( this.isPerson( item ) ) {

            return ( <Person key={ i } item={ item } /> )

        }

        return ( <Club key={ i } item={ item } /> )

    }

    renderItems( items ) {

        return items.map( ( item, i ) => this.renderItem( item, i ) )

    }

    render() {

        const filteredClubs   = this.state.clubs.filter(   e => this.checkItem( e ) )
        const filteredPersons = this.state.persons.filter( e => this.checkItem( e ) )
        const filteredAll     = [ ...filteredClubs, ...filteredPersons ]
        const countAll        = this.state.clubs.length + this.state.persons.length
        const countFiltered   = filteredClubs.length    + filteredPersons.length

        sortByKey( filteredClubs,   'Name' )
        sortByKey( filteredPersons, 'Name' )
        sortByKey( filteredAll,     'Name' )

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='search' className='subheader-icon' /> { _( 'Suche' ) }

                    </h1>

                </div>

                { ! this.state.query ?

                    <div className='text-center my-5'>{ _( 'Bitte verwenden Sie das Suchfeld im Header.' ) }</div>

                : this.state.loading ?

                    <div className='text-center my-5 fs-3'><Icon icon='spinner' spin /></div>

                : countAll ?

                    <Tab.Container defaultActiveKey='all' onSelect={ activeTab => this.setState( { activeTab } ) } activeKey={ this.state.activeTab }>

                        <Form.Control placeholder={ _( 'Suchergebnisse filtern' ) } value={ this.state.textFilter } onChange={ e => this.setState( { textFilter: e.target.value } ) } />

                        <Nav variant='pills' className='my-3'>

                            <Nav.Item><Nav.Link eventKey='all' disabled={ ! countFiltered }>{ _( 'Alle' ) } ({ countFiltered })</Nav.Link></Nav.Item>

                            <Nav.Item><Nav.Link eventKey='clubs' disabled={ ! filteredClubs.length }>{ _( 'Vereine' ) } ({ filteredClubs.length })</Nav.Link></Nav.Item>

                            <Nav.Item><Nav.Link eventKey='persons' disabled={ ! filteredPersons.length }>{ _( 'Personen' ) } ({ filteredPersons.length })</Nav.Link></Nav.Item>

                        </Nav>

                        <Tab.Content>

                            <Tab.Pane eventKey='all'>

                                { this.renderItems( filteredAll ) }

                            </Tab.Pane>

                            <Tab.Pane eventKey='clubs'>

                                { this.renderItems( filteredClubs ) }

                            </Tab.Pane>

                            <Tab.Pane eventKey='persons'>

                                { this.renderItems( filteredPersons ) }

                            </Tab.Pane>

                        </Tab.Content>

                    </Tab.Container>

                :

                    <div className='text-center my-5'>{ _( 'Keine Einträge gefunden.' ) }</div>

                }

            </>

        )

    }

}

export default withRouter( Search )