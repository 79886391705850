import { connect } from 'react-redux'
import Context from '../../components/controls/Context'
import { setUiValue } from '../../store/actions'
import { convertContexts } from '../../util'

const mapStateToProps = state => ( {

    context:  state.ui.context,
    contexts: convertContexts( ( state.auth.user && state.auth.user.Persons ) || [] ),

} )

const mapDispatchToProps = dispatch => ( {

    onSetContext: context => dispatch( setUiValue( 'context', context ) ),

} )

export default connect( mapStateToProps, mapDispatchToProps )( Context )