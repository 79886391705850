import { translate as _ } from '@morawadigital/skynet-framework'
import { FIELD_TYPE_AUTOCOMPLETE, FIELD_TYPE_NUMBER, FIELD_TYPE_TEXTAREA, FIELD_TYPE_TEXTFIELD, FIELD_TYPE_TOGGLE } from '../util/constants'

export const Player = {

    'Club':                           { __label: _( 'Verein'                              ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'clubs'             },
    'Height':                         { __label: _( 'Körpergröße'                         ), __type: FIELD_TYPE_NUMBER                                          },
    'InformationForAction':           { __label: _( 'Information For Action'              ), __type: FIELD_TYPE_TEXTAREA                                        },
    'MedicalRecords':                 { __label: _( 'Medical Records'                     ), __type: FIELD_TYPE_TEXTAREA                                        },
    'NeedsTransferCard':              { __label: _( 'Needs Transfer Card'                 ), __type: FIELD_TYPE_TOGGLE                                          },
    'Note':                           { __label: _( 'Notiz'                               ), __type: FIELD_TYPE_TEXTAREA                                        },
    'PassNr':                         { __label: _( 'Passnummer'                          ), __type: FIELD_TYPE_TEXTFIELD                                       },
    'PlayerContingent':               { __label: _( 'Player Contingent'                   ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'playerContingents' },
    'PlayerStatus':                   { __label: _( 'Player Status'                       ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'playerStatuses'    },
    'ShotCatchHand':                  { __label: _( 'Schuss-/Fanghand'                    ), __type: FIELD_TYPE_AUTOCOMPLETE, __optionsKey: 'sides'             },
    'WaiverOfMedicalConfidentiality': { __label: _( 'Waiver Of Medical Confidentiality'   ), __type: FIELD_TYPE_TOGGLE                                          },
    'Weight':                         { __label: _( 'Gewicht'                             ), __type: FIELD_TYPE_NUMBER                                          },

}