import React from 'react'
import { withRouter } from 'react-router-dom'
import { translate as _, formatDate } from '@morawadigital/skynet-framework'
import { Col, Dropdown, Placeholder } from 'react-bootstrap'
import { getFullName } from '../../util'
import Item from './Item'

class Person extends React.Component {

    renderOptions( url ) {

        return (

            <Dropdown.Item as='button' onClick={ () => this.props.history.push( url ) }>{ _( 'Bearbeiten' ) }</Dropdown.Item>

        )

    }

    render() {

        const item = this.props.item
        const url  = item && _( '/person/' ) + item.Id

        return (

            <Item
                colProps={ { xs: 12, sm: 10, xxl: 11 } }
                index=   { this.props.index            }
                item=    { item                        }
                name=    { item && getFullName( item ) }
                options= { this.renderOptions( url )   }
                url=     { url                         }
            >

                <Col xs='12' sm='2' xxl='1'>

                    { item ?

                        <div className='text-end'>{ item.Birthdate ? formatDate(  item.Birthdate ) : '' }</div>

                    :

                        <Placeholder animation='wave'><Placeholder xs='12' /></Placeholder>

                    }

                </Col>

            </Item>

        )

    }

}

export default withRouter( Person )