import { ajx, translate as _ } from '@morawadigital/skynet-framework'
import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import FieldSet from '../elements/FieldSet'
import { getFieldDefinition } from '../../fieldDefinition'
import { getBooleanValue, getPlayerLicenseStatuses, getSelectValue } from '../../util'
import { createOptions } from '../../util'

export default class PlayerLicense extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            fieldDefinition: null,
            saving:          false,
            playerLicense:   this.props.playerLicense,
            valueOptions:    { playerLicenseStatuses: getPlayerLicenseStatuses() },

        }

    }

    componentDidMount() {

        this.loadFieldDefinition()

        this.load()

    }

    componentDidUpdate() {

        if ( ( ! this.props.playerLicense && this.state.playerLicense ) || ( this.props.playerLicense && ! this.state.playerLicense ) ) {

            this.setState( { playerLicense: this.props.playerLicense } )

        }

    }

    load() {

        ajx( {

            options:       { method: 'GET' },
            success:       e => this.setState( { valueOptions: { ...this.state.valueOptions, playerLicenseTypes: createOptions( e ) } } ),
            toggleLoading: true,
            url:           'api/PlayerLicenseType/Get',

        } )

    }

    loadFieldDefinition() {

        getFieldDefinition( {

            objectName: 'PlayerLicense',
            success:    fieldDefinition => this.setState( { fieldDefinition } ),

        } )

    }

    save( e ) {

        e.preventDefault()

        this.setState( { saving: true }, () => {

            const data = {

                clubId:                       getSelectValue( this.state.playerLicense.Club                          ),
                documentArrival:              this.state.playerLicense.DocumentArrival,
                developmentLicense:           getBooleanValue( this.state.playerLicense.DevelopmentLicense           ),
                fromDate:                     this.state.playerLicense.FromDate,
                id:                           this.state.playerLicense.Id,
                licenseExtensionSecondLeague: getBooleanValue( this.state.playerLicense.LicenseExtensionSecondLeague ),
                licensePrintDate:             this.state.playerLicense.LicensePrintDate,
                licenseReturnDate:            this.state.playerLicense.LicenseReturnDate,
                note:                         this.state.playerLicense.Note,
                playerId:                     this.state.playerLicense.PlayerId,
                playerLicenseTypeId:          getSelectValue( this.state.playerLicense.PlayerLicenseType             ),
                playoffClubId:                getSelectValue( this.state.playerLicense.PlayoffClub                   ),
                release:                      getBooleanValue( this.state.playerLicense.Release                      ),
                restrictedLeagueId:           getSelectValue( this.state.playerLicense.RestrictedLeague              ),
                toDate:                       this.state.playerLicense.ToDate,

            }

            const success = e => {

                this.props.onUpdate( e )
                this.props.onHide()

            }

            ajx( {

                complete: () => this.setState( { saving: false } ),
                single:   true,
                url:      'api/PlayerLicense/AddOrEdit',

                data, success,

            } )

        } )

    }

    render() {

        return (

            <Modal show={ this.state.playerLicense !== null } onHide={ this.props.onHide } backdrop='static'>

                <Form onSubmit={ e => this.save( e ) }>

                    <fieldset disabled={ this.state.saving }>

                        <Modal.Header closeButton>

                            <Modal.Title>{ _( 'Lizenz' ) }</Modal.Title>

                        </Modal.Header>

                        <Modal.Body>

                            { this.state.playerLicense &&

                                <FieldSet
                                    disabled={ this.state.saving }
                                    fieldDefinition={ this.state.fieldDefinition }
                                    onChange={ ( e, f ) => this.setState( { playerLicense: { ...this.state.playerLicense, [ e ]: f } } ) }
                                    valueOptions={ { ...( this.props.valueOptions || {} ), ...this.state.valueOptions } }
                                    values={ this.state.playerLicense }
                                />

                            }

                        </Modal.Body>

                        <Modal.Footer>

                            <Button variant='secondary' onClick={ this.props.onHide }>{ _( 'Abbrechen' ) }</Button>

                            <Button type='submit'>{ _( 'Speichern' ) }</Button>

                        </Modal.Footer>

                    </fieldset>

                </Form>

            </Modal>

        )

    }

}