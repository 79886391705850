import { Link } from 'react-router-dom'
import Select from 'react-select'
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap'
import { Icon, ajx, translate as _ } from '@morawadigital/skynet-framework'
import ItemsPage from './ItemsPage'
import Person from '../items/Person'
import { createOptions, findTextInObject } from '../../util'

export default class Persons extends ItemsPage {

    constructor( props ) {

        super( props )

        this.dataMethod    = 'POST'
        this.itemComponent = Person
        this.pageIcon      = 'users'
        this.pageTitle     = _( 'Personen' )

        if ( this.props.context && this.props.context.club ) {

            this.pageTitle = <>{ this.pageTitle } <span className='fw-300'>{ this.props.context.club.name }</span></>

        }

    }

    changeClubFilter( e ) {

        this.setState( { clubFilterPersons: e } )

    }

    checkItem( item ) {

        if ( this.props.textFilter && ! findTextInObject( item, this.props.textFilter, [ 'Lastname', 'Firstname' ] ) ) {

            return false

        }

        return true

    }

    getDataParams() {

        if ( this.props.context && this.props.context.club ) {

            return { clubIds: { __arr: true, values: [ this.props.context.club.id ] } }

        }

        return this.state.clubFilterPersons.length ? { clubIds: { __arr: true, values: this.state.clubFilterPersons.map( e => e.value ) } } : {}

    }

    getDataUrl() {

        return 'api/Person/Get'

    }

    loadFilters() {

        ajx( {

            options:       { method: 'GET' },
            success:       e => this.setState( { filterData: { ...this.state.filterData, clubs: createOptions( e ) } } ),
            toggleLoading: true,
            url:           'api/Club/Get',

        } )

    }

    loadPersons( e ) {

        e.preventDefault()

        this.props.onUiValueChange( 'clubFilterPersons', this.state.clubFilterPersons )

        setTimeout( () => this.loadItems(), 200 )

    }

    renderFilters() {

        return (

            <div className='sticky-filters'>

                <Row>

                    { ( ! this.props.context || ! this.props.context.club ) &&

                        <Col>

                            <Form onSubmit={ e => this.loadPersons( e ) }>

                                <InputGroup>

                                    <Select
                                        className='flex-grow-1'
                                        isClearable={ true }
                                        isMulti={ true }
                                        noOptionsMessage={ () => _( 'Keine Optionen verfügbar' ) }
                                        onChange={ e => this.changeClubFilter( e ) }
                                        options={ this.state.filterData.clubs }
                                        placeholder={ _( 'Verein' ) }
                                        value={ this.state.clubFilterPersons }
                                    />

                                    <Button type='submit'>{ _( 'Laden' ) }</Button>

                                </InputGroup>

                            </Form>

                        </Col>

                    }

                    <Col>

                        <Form.Control
                            onChange={ e => this.props.onUiValueChange( 'textFilterPersons', e.target.value ) }
                            placeholder={ _( 'Personen filtern...' ) }
                            value={ this.props.textFilter }
                        />

                    </Col>

                </Row>

            </div>

        )

    }

    renderSubheaderBlock() {

        return (

            <Link to={ _( '/person/neu' ) } className='btn btn-primary'><Icon icon='plus' /> { _( 'Neu' ) }</Link>

        )

    }

}