import { connect } from 'react-redux'
import { setUiValue } from '../../store/actions'
import TransferRequests from '../../components/pages/TransferRequests'

const mapStateToProps = state => ( {

    context:      state.ui.context,
    statusFilter: state.ui.transferRequestStatusFilter || '',
    loading:      state.app.loading,
    textFilter:   state.ui.textFilterTransferRequests  || '',

} )

const mapDispatchToProps = dispatch => ( {

    onUiValueChange: ( key, value ) => dispatch( setUiValue( key, value ) ),

} )

export default connect( mapStateToProps, mapDispatchToProps )( TransferRequests )