import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Form, InputGroup, Nav, Tab } from 'react-bootstrap'
import { Icon, translate as _ } from '@morawadigital/skynet-framework'
import Field from './Field'
import { FIELD_TYPE_AUTOCOMPLETE, FIELD_TYPE_FLEX_DATE, FIELD_TYPE_RANGE, FIELD_TYPE_TEXTFIELD } from '../../util/constants'
import { getSearchFields } from '../../util/search'
import { getGenders } from '../../util'
import HeaderItem from '../elements/HeaderItem'

class Search extends React.Component {

    #fields       = getSearchFields()
    #valueOptions = { genders: getGenders() }

    constructor( props ) {

        super( props )

        let query = ''

        if ( props.location.pathname.indexOf( _( '/suche/' ) ) === 0 ) {

            query = props.location.pathname.replace( _( '/suche/' ), '' )

        }

        this.state = {

            activeTab:   'person',
            birthdate:   '--',
            firstName:   '',
            gender:      null,
            lastName:    '',
            optionsType: null,
            showItem:    false,
            showOptions: false,
            yob:         '-',

            query,

        }

    }

    stringifySearch( e, group ) {

        e.preventDefault()

        const search = []
        let query    = ''

        this.#fields.filter( e => e.group === group ).forEach( field => this.state[ field.name ] && search.push( { keyWord: field.keyWord, type: field.type, value: this.state[ field.name ] } ) )

        if ( search.length ) {

            query = search.map( e => {

                let value = e.value

                if ( e.type === FIELD_TYPE_AUTOCOMPLETE ) {

                    value = value.label

                } else if ( e.type === FIELD_TYPE_FLEX_DATE ) {

                    if ( value === '--' ) {

                        return ''

                    }

                } else if ( e.type === FIELD_TYPE_RANGE ) {

                    if ( value === '-' ) {

                        return ''

                    }

                }

                return e.keyWord + ':' + ( value.includes( ' ' ) ? ( '"' + value + '"' ) : value )

            } ).join( ' ' ).trim()

            query && this.setState( { query, showOptions: false }, () => this.submitSearch() )

        }

    }

    submitSearch( e ) {

        e && e.preventDefault()

        if ( this.state.query ) {

            this.setState( { showOptions: false }, () => {

                this.updateHistory()

                this.props.history.push( _( '/suche/' ) + this.state.query )

            } )

        }

    }

    updateHistory() {

        let searchHistory = [ ...new Set( [ this.state.query, ...this.props.searchHistory ] ) ]

        searchHistory.length > 10 && searchHistory.splice( 10 )

        this.props.onSetSearchHistory( searchHistory )

    }

    renderForm( group ) {

        return (

            <Form onSubmit={ e => this.stringifySearch( e, group ) }>

                <div className='my-3'>

                    { this.#fields.filter( e => e.group === group ).map( ( e, i ) =>

                        <Field
                            key={ i }
                            field={ { object: 'search', property: e.name, __label: e.label, __type: e.type || FIELD_TYPE_TEXTFIELD, __optionsKey: e.optionsKey } }
                            onChange={ ( name, value ) => this.setState( { [ name ]: value } ) }
                            value={ this.state[ e.name ] }
                            valueOptions={ this.#valueOptions }
                        />

                    ) }

                </div>

                <div className='text-end'>

                    <Button type='submit'>{ _( 'Suchen' ) }</Button>

                </div>

            </Form>

        )

    }

    renderFormTabs() {

        return (

            <div className='p-3'>

                <Tab.Container defaultActiveKey='person' onSelect={ activeTab => this.setState( { activeTab } ) } activeKey={ this.state.activeTab }>

                    <Nav variant='pills' fill>

                        <Nav.Item role='button'><Nav.Link eventKey='person'>{ _( 'Personen' ) }</Nav.Link></Nav.Item>

                        <Nav.Item role='button'><Nav.Link eventKey='club'>{   _( 'Vereine'  ) }</Nav.Link></Nav.Item>

                    </Nav>

                    <Tab.Content>

                        <Tab.Pane eventKey='person'>

                            { this.renderForm( 'person' ) }

                        </Tab.Pane>

                        <Tab.Pane eventKey='club'>

                            { this.renderForm( 'club' ) }

                        </Tab.Pane>

                    </Tab.Content>

                </Tab.Container>

            </div>

        )

    }

    renderInput() {

        return (

            <Form onSubmit={ e => this.submitSearch( e ) }>

                <InputGroup>

                    <Form.Control
                        className='header-item-input'
                        onBlur={ () => this.state.optionsType === 'suggestions' && this.state.showOptions && setTimeout( () => this.setState( { showOptions: false } ), 100 ) }
                        onChange={ e => this.setState( { query: e.target.value } ) }
                        onClick={ () => ! this.state.showOptions && this.setState( { optionsType: 'suggestions', showOptions: true } ) }
                        placeholder={ _( 'Suche' ) }
                        value={ this.state.query }
                    />

                    <Button
                        className='header-item-options-btn'
                        onClick={ () => this.setState( { optionsType: 'form', showOptions: ! this.state.showOptions } ) }
                        variant={ this.state.showOptions? 'secondary' : 'outline-secondary' }
                    >

                        <Icon icon='sliders' />

                    </Button>

                </InputGroup>

            </Form>

        )

    }



    renderOptions() {

        return this.state.optionsType === 'suggestions' && this.props.searchHistory.length ? this.renderSuggestions() : this.state.optionsType === 'form' ? this.renderFormTabs() : null

    }

    renderSuggestion( query, i ) {

        return (

            <div className='header-item-suggestion' key={ i } onClick={ () => this.setState( { query }, () => this.submitSearch() ) }>{ query }</div>

        )

    }

    renderSuggestions() {

        return this.props.searchHistory.map( ( e, i ) => this.renderSuggestion( e, i ) )

    }

    render() {

        return (

            <HeaderItem
                buttonIcon=  { 'search'                                                   }
                item=        { this.renderInput()                                         }
                onToggleItem={ () => this.setState( { showItem: ! this.state.showItem } ) }
                options=     { this.renderOptions()                                       }
                showItem=    { this.state.showItem                                        }
                showOptions= { this.state.showOptions                                     }
            />

        )

    }

}

export default withRouter( Search )