import Select from 'react-select'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { translate as _ } from '@morawadigital/skynet-framework'
import ItemsPage from './ItemsPage'
import TransferRequestItem from '../items/TransferRequest'
import TransferRequestModal from '../modals/TransferRequest'
import { findTextInObject, getFullName, getTransferRequestStatuses, updateItem } from '../../util'

export default class TransferRequests extends ItemsPage {

    constructor( props ) {

        super( props )

        this.itemComponent = TransferRequestItem
        this.pageIcon      = 'code-pull-request'
        this.pageTitle     = _( 'Anträge' )

    }

    checkItem( item ) {

        if ( this.props.textFilter && ! findTextInObject( item, this.props.textFilter, [ 'clubName', 'personName' ] ) ) {

            return false

        }

        if ( this.props.statusFilter && this.props.statusFilter.length && ! this.props.statusFilter.find( e => e.value === item.Status ) ) {

            return false

        }

        return true

    }

    convertItems( items ) {

        return items.map( e => ( {

            clubName:   e.Club   ? e.Club.Name                    : '',
            personName: e.Player ? getFullName( e.Player.Person ) : '',

            ...e

        } ) )

    }

    getItemProps() {

        return {

            onUpdate:    e => updateItem( this.state.items, e ),
            respondable: this.props.context && this.props.context.federation,

        }

    }

    getDataParams() {

        const params = {}

        if ( this.props.context ) {

            if ( this.props.context.federation ) { params.fedId  = this.props.context.federation.id }
            if ( this.props.context.club       ) { params.clubId = this.props.context.club.id       }

        }

        return params

    }

    getDataUrl() {

        return 'api/TransferRequest/Get'

    }

    renderSubheaderBlock() {

        return (

            <Button onClick={ () => this.setState( { transferRequestModalOpen: true } ) }>{ _( 'Antrag erstellen' ) }</Button>

        )

    }

    renderFilters() {

        return (

            <Form className='sticky-filters'>

                <Row>

                    <Col>

                        <Form.Control
                            onChange={ e => this.props.onUiValueChange( 'textFilterTransferRequests', e.target.value ) }
                            placeholder={ _( 'Anträge filtern...' ) }
                            value={ this.props.textFilter }
                        />

                    </Col>

                    <Col>

                        <Select
                            isClearable={ true }
                            isMulti={ true }
                            noOptionsMessage={ () => _( 'Keine Optionen verfügbar' ) }
                            onChange={ e => this.props.onUiValueChange( 'transferRequestStatusFilter', e ) }
                            options={ getTransferRequestStatuses() }
                            placeholder={ _( 'Status' ) }
                            value={ this.props.statusFilter }
                        />

                    </Col>

                </Row>

            </Form>

        )

    }

    renderFooter() {

        return (

            <TransferRequestModal onHide={ () => this.setState( { transferRequestModalOpen: false } ) } show={ this.state.transferRequestModalOpen } />

        )

    }

}