import React from 'react'
import { withRouter } from 'react-router-dom'
import { translate as _, formatDate } from '@morawadigital/skynet-framework'
import { Col, Dropdown, OverlayTrigger, Placeholder, Tooltip } from 'react-bootstrap'
import Item from './Item'
import { getPlayerLicenseStatus } from '../../util'

class PlayerLicense extends React.Component {

    renderOptions() {

        return (

            this.props.editable &&

                <>

                    <Dropdown.Item as='button' onClick={ () => this.props.onEdit( this.props.item ) }>{ _( 'Bearbeiten' ) }</Dropdown.Item>

                    <Dropdown.Item as='button' onClick={ () => this.props.onDelete( this.props.item ) }>{ _( 'Löschen' ) }</Dropdown.Item>

                </>

        )

    }

    render() {

        // TODO PlayerLicense
        // {
        //     "Id":269060,
        //     "FromDate":"1997-01-16T00:00:00",
        //     "ToDate":"1997-05-31T00:00:00",
        //     "Note":"",
        //     "Status":4,
        //     "DocumentArrival":"1996-12-23T00:00:00",
        //     "LicensePrintDate":"1997-01-16T00:00:00",
        //     "LicenseReturnDate":null,
        //     "Release":false,
        //     "DevelopmentLicense":null,
        //     "LicenseExtensionSecondLeague":false,
        //     "RestrictedLeague":null,
        //     "Club":{
        //         "Id":11772,
        //         "Name":"SC Riessersee Eishockey e.V.",
        //         "Shortname":"SCRI",
        //         "Passname":"SC Riessersee Eishockey e.V.",
        //         "Icon":"78698b98-5296-4db4-b408-f3aab68e6965.png"
        //     },
        //     "PlayoffClub":null,
        //     "PlayerLicenseType":{
        //         "Id":1,
        //         "Name":"Spielberechtigung",
        //         "LicenseConfig":null
        //     }
        // }

        const item   = this.props.item
        const status = item && getPlayerLicenseStatus( item.Status )

        return (

            <Item
                colProps={ { xs: 12, sm: 10, md: 5 }                                     }
                item=    { item                                                          }
                name=    { item && item.PlayerLicenseType && item.PlayerLicenseType.Name }
                options= { this.renderOptions()                                          }
            >

                <Col xs='4' sm='2'>

                    { item ?

                        status && status.label

                    :

                        <Placeholder animation='wave'><Placeholder xs='12' /></Placeholder>

                    }

                </Col>

                <Col xs='8' sm='6' md='3'>

                    { item ?

                        formatDate( item.FromDate ) + '-' + formatDate( item.ToDate )

                    :

                        <Placeholder animation='wave'><Placeholder xs='12' /></Placeholder>

                    }

                </Col>

                <Col xs='12' sm='6' md='2'>

                    { item ?

                        item.Club && <OverlayTrigger overlay={ <Tooltip>{ item.Club.Name }</Tooltip> }><span>{ item.Club.Shortname }</span></OverlayTrigger>

                    :

                        <Placeholder animation='wave'><Placeholder xs='12' /></Placeholder>

                    }

                </Col>

            </Item>

        )

    }

}

export default withRouter( PlayerLicense )