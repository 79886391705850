import { ajx, translate as _ } from '@morawadigital/skynet-framework'
import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import FieldSet from '../elements/FieldSet'
import { getFieldDefinition } from '../../fieldDefinition'
import { getSelectValue } from '../../util'

export default class PlayerLicenseRequest extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            fieldDefinition:      null,
            saving:               false,
            playerLicenseRequest: this.createLicenseRequest(),

        }

    }

    componentDidMount() {

        this.loadFieldDefinition()

    }

    createLicenseRequest() {

        return {

            Note:              '',
            PlayerLicenseType: null,

        }

    }

    loadFieldDefinition() {

        getFieldDefinition( {

            objectName: 'PlayerLicenseRequest',
            success:    fieldDefinition => this.setState( { fieldDefinition } ),

        } )

    }

    save( e ) {

        e.preventDefault()

        this.setState( { saving: true }, () => {

            const data = {

                clubId:          this.props.clubId,
                note:            this.state.playerLicenseRequest.Note,
                playerId:        this.props.playerId,
                playerLicTypeId: getSelectValue( this.state.playerLicenseRequest.PlayerLicenseType ),

            }

            const success = e => {

                this.setState( { playerLicenseRequest: this.createLicenseRequest() }, () => {

                    this.props.onAdd( e )
                    this.props.onHide()

                } )

            }

            ajx( {

                complete: () => this.setState( { saving: false } ),
                single:   true,
                url:      'api/PlayerLicenseRequest/AddOrEdit',

                data, success,

            } )

        } )

    }

    render() {

        return (

            <Modal show={ this.props.show } onHide={ this.props.onHide } backdrop='static'>

                <Form onSubmit={ e => this.save( e ) }>

                    <fieldset disabled={ this.state.saving }>

                        <Modal.Header closeButton>

                            <Modal.Title>{ _( 'Lizenz beantragen' ) }</Modal.Title>

                        </Modal.Header>

                        <Modal.Body>

                            <FieldSet
                                disabled={ this.state.saving }
                                fieldDefinition={ this.state.fieldDefinition }
                                onChange={ ( e, f ) => this.setState( { playerLicenseRequest: { ...this.state.playerLicenseRequest, [ e ]: f } } ) }
                                valueOptions={ { playerLicenseTypes: this.props.playerLicenseTypes } }
                                values={ this.state.playerLicenseRequest }
                            />

                        </Modal.Body>

                        <Modal.Footer>

                            <Button variant='secondary' onClick={ this.props.onHide }>{ _( 'Abbrechen' ) }</Button>

                            <Button type='submit'>{ _( 'Lizenz beantragen' ) }</Button>

                        </Modal.Footer>

                    </fieldset>

                </Form>

            </Modal>

        )

    }

}