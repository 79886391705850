import React from 'react'
import { Card, Form, Nav, Tab } from 'react-bootstrap'
import { Prompt, withRouter } from 'react-router-dom'
import { Icon, ajx, ajxs, translate as _ } from '@morawadigital/skynet-framework'
import { getFieldDefinition } from '../../fieldDefinition'
import Floaties from '../controls/Floaties'
import { createOptions, deleteItem, getBooleanValue, getSelectValue, updateItem } from '../../util'
import FieldSet from '../elements/FieldSet'
import Documents from '../elements/Documents'
import TabButton from '../elements/TabButton'

class Federation extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            activeTab:         'basicData',
            documents:             [],
            federation:        this.createFederation(),
            fieldDefinition:   null,
            hasUnsavedChanges: false,
            saving:            false,
            valueOptions:      {},

        }

        this.checkUnsavedChanges = this.checkUnsavedChanges.bind( this )

    }

    componentDidMount() {

        window.addEventListener( 'beforeunload', this.checkUnsavedChanges )

        this.loadFieldDefinition()

        this.load()

    }

    componentWillUnmount() {

        window.removeEventListener( 'beforeunload', this.checkUnsavedChanges )

    }

    checkUnsavedChanges( e ) {

        if ( this.state.hasUnsavedChanges ) {

            e.preventDefault()

            return e.returnValue = _( 'Sind Sie sicher, dass Sie die Seite verlassen möchten? Ungespeicherte Änderungen gehen verloren.' )

        }

    }

    createFederation( federation ) {

        const createdFederation = {

            Address:          null,
            DistrictCourt:    '',
            Homepage:         '',
            Icon:             '',
            Id:               0,
            MainFederation:   false,
            Name:             '',
            ParentFederation: null,
            RegistryNumber:   '',
            Shortname:        '',
            TaxId:            '',
            TaxNumber:        '',

            ...( federation || {} ),

        }

        if ( federation ) {

            createdFederation.ParentFederation = federation.ParentFederation ? { label: federation.ParentFederation.Name, value: federation.ParentFederation.Id } : null

        }

        return createdFederation

    }

    delete() {

        // TODO Delete federation
        alert( 'TODO Delete federation' )

    }

    getFederationId() {

        return this.state.federation.Id || this.props.match.params.id || 0

    }

    load() {

        const items = [

            { options: { method: 'GET' }, success: e => this.setState( { valueOptions: { ...this.state.valueOptions, federations: createOptions( e ) } } ), url: 'api/Federation/Get' },

        ]

        if ( this.getFederationId() !== _( 'neu' ) ) {

            items.push( {

                data:    { id: this.getFederationId() },
                options: { method: 'GET' },
                single:  true,
                success: e => this.setState( { federation: this.createFederation( e ), documents: e.FederationDocuments } ),
                url:     'api/Federation/Get',

            } )

        }

        ajxs( { toggleLoading: true }, items )

    }

    loadFieldDefinition() {

        getFieldDefinition( {

            objectName: 'Federation',
            success:    fieldDefinition => this.setState( { fieldDefinition } ),

        } )

    }

    save( e ) {

        e.preventDefault()

        this.setState( { saving: true }, () => {

            const data = {

                address:            JSON.stringify( this.state.federation.Address          ),
                districtCourt:      this.state.federation.DistrictCourt,
                homepage:           this.state.federation.Homepage,
                icon:               this.state.federation.Icon,
                id:                 this.state.federation.Id,
                mainFederation:     getBooleanValue( this.state.federation.MainFederation  ),
                name:               this.state.federation.Name,
                parentFederationId: getSelectValue( this.state.federation.ParentFederation ),
                registryNumber:     this.state.federation.RegistryNumber,
                shortname:          this.state.federation.Shortname,
                taxId:              this.state.federation.TaxId,
                taxNumber:          this.state.federation.TaxNumber,

            }

            const success = e => {

                const federationId = this.getFederationId()
                const state        = { hasUnsavedChanges: false }

                if ( ! this.state.federation.Id ) {

                    state.federation = { ...this.state.federation, Id: e.Id }

                }

                this.setState( state, () => federationId === _( 'neu' ) && this.props.history.push( _( '/verband/' ) + e.Id ) )

            }

            ajx( {

                complete: () => this.setState( { saving: false } ),
                single:   true,
                url:      'api/Federation/AddOrEdit',

                data, success,

            } )

        } )

    }

    render() {

        const formDisabled = this.props.loading || this.state.saving

        return (

            <Form onSubmit={ e => this.save( e ) }>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='cube' className='subheader-icon' /> { _( 'Verband' ) } { this.state.federation && <span className='fw-300'>{ this.state.federation.Name || '' }</span> }

                    </h1>

                </div>

                <Card>

                    <fieldset disabled={ formDisabled }>

                        <Tab.Container defaultActiveKey='basicData' onSelect={ activeTab => this.setState( { activeTab } ) } activeKey={ this.state.activeTab }>

                            <Card.Header>

                                <Nav variant='pills'>

                                    <TabButton eventKey='basicData' label={ _( 'Stammdaten' ) }                                         />

                                    <TabButton eventKey='documents' label={ _( 'Dateien'    ) } disabled={ ! this.state.federation.Id } />

                                </Nav>

                            </Card.Header>

                            <Card.Body>

                                <Tab.Content>

                                    <Tab.Pane eventKey='basicData'>

                                        <FieldSet
                                            disabled={ formDisabled }
                                            fieldDefinition={ this.state.fieldDefinition }
                                            onChange={ ( e, f ) => this.setState( { federation: { ...this.state.federation, [ e ]: f }, hasUnsavedChanges: true } ) }
                                            valueOptions={ this.state.valueOptions }
                                            values={ this.state.federation }
                                        />

                                    </Tab.Pane>

                                    <Tab.Pane eventKey='documents'>

                                        <Documents
                                            disabled={ this.props.loading }
                                            documents={ this.state.documents }
                                            objectName='Federation'
                                            onChange={ e => this.setState( { documents: updateItem( this.state.documents, e ) } ) }
                                            onDelete={ e => this.setState( { documents: deleteItem( this.state.documents, e ) } ) }
                                            uploadParams={ { fedId: this.getFederationId() } }
                                        />

                                    </Tab.Pane>

                                </Tab.Content>

                            </Card.Body>

                        </Tab.Container>

                    </fieldset>

                </Card>

                { this.state.activeTab === 'basicData' && <Floaties buttons={ [

                    { icon: 'save',  label: _( 'Speichern' ), disabled: formDisabled, type: 'submit' },
                    // { icon: 'trash', label: _( 'Löschen'   ), disabled: formDisabled, onClick: () => this.delete(), variant: 'danger', outline: true, position: 'end' },

                ] } /> }

                <Prompt message={ _( 'Sind Sie sicher, dass Sie die Seite verlassen möchten? Ungespeicherte Änderungen gehen verloren.' ) } when={ this.state.hasUnsavedChanges } />

            </Form>

        )

    }

}

export default withRouter( Federation )