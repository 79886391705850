import React from 'react'
import { Icon, ajx, translate as _ } from '@morawadigital/skynet-framework'

export default class ItemsPage extends React.Component {

    itemComponent
    pageIcon
    pageTitle

    constructor( props ) {

        super( props )

        this.state = {

            clubFilterPersons: this.props.clubFilterPersons,
            filterData:        {},
            items:             null,

        }

    }

    componentDidMount() {

        this.loadFilters()
        this.loadItems()

    }

    checkItem() {

        return true

    }

    convertItems( e ) {

        return e

    }

    getDataParams() {

        return {}

    }

    getDataUrl() {}

    getItemProps() {

        return {}

    }

    loadFilters() {}

    loadItems() {

        ajx( {

            data:          this.getDataParams(),
            options:       { method: this.dataMethod || 'GET' },
            success:       e => this.setState( { items: this.convertItems( e ) } ),
            timeout:       400,
            toggleLoading: true,
            url:           this.getDataUrl(),

        } )

    }

    renderFilters() {}

    renderFooter() {}

    renderSubheaderBlock() {}

    render() {

        const ItemComponent = this.itemComponent
        const filteredItems = this.state.items ? this.state.items.filter( e => this.checkItem( e ) ) : null

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon={ this.pageIcon } className='subheader-icon' /> { this.pageTitle }

                    </h1>

                    <div className='subheader-block'>

                        { this.renderSubheaderBlock() }

                    </div>

                </div>

                { this.renderFilters() }

                { this.props.loading ?

                    Array.from( { length: 3 } ).map( ( e, i ) => <ItemComponent key={ i } index={ i } /> )

                : filteredItems && ! filteredItems.length ?

                    <div className='my-3 text-center'>{ _( 'Keine Einträge gefunden.' ) }</div>

                : filteredItems ?

                    <>

                        <div className='text-center text-muted mt-2 mb-3'>{ filteredItems.length + ' ' + ( filteredItems.length === 1 ? _( 'Eintrag' ) : _( 'Einträge' ) ) }</div>

                        { filteredItems.map( ( item, i ) => <ItemComponent key={ i } item={ item } { ...this.getItemProps() } /> ) }

                    </>

                :

                    ''

                }

                { this.renderFooter() }

            </>

        )

    }

}