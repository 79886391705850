import { /* ajx,  */translate as _ } from '@morawadigital/skynet-framework'
import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'

export default class TransferRequest extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            saving:          false,
            show:            false,
            transferRequest: {},

        }

    }

    componentDidUpdate() {

        this.props.show !== this.state.show && this.setState( {

            show:            this.props.show,
            transferRequest: {},

        } )

    }

    save( e ) {

        e.preventDefault()

        // this.setState( { saving: true }, () => {

        //     const data = {

        //         clubId:          this.props.clubId,
        //         note:            this.state.playerLicenseRequest.Note,
        //         playerId:        this.props.playerId,
        //         playerLicTypeId: getSelectValue( this.state.playerLicenseRequest.PlayerLicenseType ),

        //     }

        //     const success = e => {

        //         this.props.onAdd( e )
        //         this.props.onHide()

        //     }

        //     ajx( {

        //         complete: () => this.setState( { saving: false } ),
        //         single:   true,
        //         url:      'api/PlayerLicenseRequest/AddOrEdit',

        //         data, success,

        //     } )

        // } )

    }

    render() {

        return (

            <Modal show={ this.props.show } onHide={ this.props.onHide } backdrop='static' size='lg' scrollable>

                <Form onSubmit={ e => this.save( e ) }>

                    <fieldset disabled={ this.state.saving }>

                        <Modal.Header closeButton>

                            <Modal.Title>{ _( 'Antrag erstellen' ) }</Modal.Title>

                        </Modal.Header>

                        <Modal.Body>

                            <ol>

                                <li>PlayerLicenseType/Get</li>
                                <li>TransferRequest/GetPlayer</li>
                                <li>TransferRequest/Create</li>

                            </ol>

                        </Modal.Body>

                        <Modal.Footer>

                            <Button variant='secondary' onClick={ this.props.onHide }>{ _( 'Abbrechen' ) }</Button>

                            <Button type='submit'>{ _( 'Weiter' ) }</Button>

                        </Modal.Footer>

                    </fieldset>

                </Form>

            </Modal>

        )

    }

}