import React from 'react'
import { withRouter } from 'react-router-dom'
import Item from './Item'

class TransferRequest extends React.Component {

    render() {

        // TODO TransferRequest

        const item = this.props.item

        return (

            <Item
                item={ item                    }
                name={ item && item.personName }
            />

        )

    }

}

export default withRouter( TransferRequest )