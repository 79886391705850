import React from 'react'
import { withRouter } from 'react-router-dom'
import { Icon, translate as _, ajx, formatDateTime } from '@morawadigital/skynet-framework'
import { Alert, Col, Dropdown, OverlayTrigger, Placeholder, SplitButton, Tooltip } from 'react-bootstrap'
import Item from './Item'
import { getFullName, getPlayerLicenseRequestStatus } from '../../util'
import ItemOverlay from '../elements/ItemOverlay'
import { PLAYER_LICENSE_REQUEST_STATUS_ACCEPTED, PLAYER_LICENSE_REQUEST_STATUS_PENDING, PLAYER_LICENSE_REQUEST_STATUS_REJECTED } from '../../util/constants'

class PlayerLicenseRequest extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            overlayText: null,

        }

    }

    confirm() {

        this.setState( { overlayText: <><Icon icon='spinner' spin /> { _( 'Wird akzeptiert...' ) }</> }, () =>

            ajx( {

                complete: () => setTimeout( () => this.setState( { overlayText: null } ), 2000 ),
                data:     { id: this.props.item.Id, status: PLAYER_LICENSE_REQUEST_STATUS_ACCEPTED },
                success:  () => this.setState( { overlayText: <><Icon icon='check' /> { _( 'Akzeptiert' ) }</> }, () => this.props.onUpdate( { ...this.props.item, Status: PLAYER_LICENSE_REQUEST_STATUS_ACCEPTED } ) ),
                url:      'api/PlayerLicenseRequest/Respond',

            } )

        )

    }

    reject() {

        this.setState( { overlayText: <><Icon icon='spinner' spin /> { _( 'Wird abgelehnt...' ) }</> }, () =>

            ajx( {

                complete: () => setTimeout( () => this.setState( { overlayText: null } ), 3000 ),
                data:     { id: this.props.item.Id, status: PLAYER_LICENSE_REQUEST_STATUS_REJECTED },
                success:  () => this.setState( { overlayText: <><Icon icon='times' /> { _( 'Abgelehnt' ) }</> }, () => this.props.onUpdate( { ...this.props.item, Status: PLAYER_LICENSE_REQUEST_STATUS_REJECTED } ) ),
                url:      'api/PlayerLicenseRequest/Respond',

            } )

        )

    }

    renderFooter( item ) {

        return (

            <>

                { item && ( item.Note || item.RequestBy ) &&

                    <Alert variant='info' className='mt-2 mb-0 p-1'>

                        { item.RequestBy && <span className='fw-700'>{ getFullName( item.RequestBy ) }</span> }

                        { item.RequestBy && item.Note && ': ' }

                        { item.Note && <span className='fst-italic'>{ item.Note }</span> }

                    </Alert>

                }

                { this.state.overlayText && <ItemOverlay>{ this.state.overlayText }</ItemOverlay> }

            </>

        )

    }

    renderStatusLabel( label, icon, text, solvedBy, solvedDate ) {

        if ( solvedBy || solvedDate ) {

            return (

                <OverlayTrigger overlay={

                    <Tooltip>

                        { solvedBy && getFullName( solvedBy ) }

                        { solvedBy && solvedDate && <br /> }

                        { solvedDate && formatDateTime( solvedDate ) }

                    </Tooltip>

                }>

                    <span className={ 'text-' + text }><Icon icon={ icon } /> { label }</span>

                </OverlayTrigger>

            )

        }

        return (

            <span className={ 'text-' + text }><Icon icon={ icon } /> { label }</span>

        )

    }

    render() {

        // TODO PlayerLicenseRequest
        // {
        //     "Id":2,
        //     "PlayerLicenseType":{
        //         "Id":2,
        //         "Name":"Förderlizenz",
        //         "LicenseConfig":null
        //     },
        //     "Club":{
        //         "Id":11569,
        //         "Name":"EHC Straubing e. V.",
        //         "Shortname":"EHCSTR",
        //         "Passname":"EHC Straubing",
        //         "Icon":"78e7339a-738a-4bc9-a1f5-d4c059e104fc.png"
        //     },
        //     "Note":"Bitte um Ausstellung",
        //     "Status":0,
        //     Player: {
        //         Id: 210174
        //         PassNr: "508028"
        //         Person: {
        //             BirthDate: "2010-11-23"
        //             FirstName: "Emilio"
        //             Id: 38058
        //             LastName: "Barbu"
        //         }
        //     }
        //     "RequestDate":"2023-06-20T10:30:00",
        //     "RequestBy":{
        //         "Id":1,
        //         "Lastname":"Müsterfrau",
        //         "Firstname":"Jacqueline",
        //         "Birthname":"Hüber",
        //         "Gender":1,
        //         "Birthdate":"2000-01-01T00:00:00",
        //         "Image":"null"
        //     },
        //     "SolvedDate":null,
        //     "SolvedBy":null
        // }

        const item       = this.props.item
        const personName = item && item.personName
        const status     = item && getPlayerLicenseRequestStatus( item.Status )
        const url        = item && item.Player && item.Player.Person && _( '/person/' ) + item.Player.Person.Id

        console.log( item )

        return (

            <Item
                colProps={ { xs: personName ? 8 : 12, md: 3 }                                              }
                footer=  { this.renderFooter( item )                                                       }
                item=    { item                                                                            }
                name=    { personName || ( item && item.PlayerLicenseType && item.PlayerLicenseType.Name ) }
                url=     { url                                                                             }
            >

                { personName &&

                    <Col xs='4' md='3' lg='2' className='text-end text-md-start'>

                        { item ?

                            item.PlayerLicenseType && item.PlayerLicenseType.Name

                        :

                            <Placeholder animation='wave'><Placeholder xs='12' /></Placeholder>

                        }

                    </Col>

                }

                <Col xs='6' md={ personName ? 3 : 6 } lg={ personName ? 2 : 4}>

                    { item ?

                        item.Club && <OverlayTrigger overlay={ <Tooltip>{ item.Club.Name }</Tooltip> }><span>{ item.Club.Shortname }</span></OverlayTrigger>

                    :

                        <Placeholder animation='wave'><Placeholder xs='12' /></Placeholder>

                    }

                </Col>

                <Col xs='6' md='3' lg={ 2 } className='text-end'>

                    { item ?

                        item.RequestDate && formatDateTime( item.RequestDate)

                    :

                        <Placeholder animation='wave'><Placeholder xs='12' /></Placeholder>

                    }

                </Col>

                <Col xs='12' lg='3' className='my-2 text-center text-lg-end'>

                    { item ? (

                        status ? (

                            status.value === PLAYER_LICENSE_REQUEST_STATUS_PENDING ?

                                ( this.props.respondable ?

                                    <SplitButton
                                        align='end'
                                        onClick={ () => this.confirm() }
                                        title={ _( 'Akzeptieren' ) }
                                    >

                                        <Dropdown.Item as='button' onClick={ () => this.reject() }>{ _( 'Ablehnen' ) }</Dropdown.Item>

                                    </SplitButton>

                                :

                                    this.renderStatusLabel( status.label, 'clock', 'info' )

                                )

                            : status.value === PLAYER_LICENSE_REQUEST_STATUS_ACCEPTED ?

                                this.renderStatusLabel( status.label, 'check', 'success', item.SolvedBy, item.SolvedDate )

                            : status.value === PLAYER_LICENSE_REQUEST_STATUS_REJECTED ?

                                this.renderStatusLabel( status.label, 'times', 'danger', item.SolvedBy, item.SolvedDate )

                            :

                                ''

                        ) : ''

                    ) :

                        <Placeholder animation='wave'><Placeholder xs='12' /></Placeholder>

                    }

                </Col>

            </Item>

        )

    }

}

export default withRouter( PlayerLicenseRequest )