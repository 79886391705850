import React from 'react'
import Field from '../controls/Field'

export default class FieldSet extends React.Component {

    render() {

        return (

            this.props.fieldDefinition && this.props.fieldDefinition.map( ( field, i ) =>

                <Field
                    disabled={ this.props.disabled }
                    field={ field }
                    key={ i }
                    onChange={ ( e, f ) => this.props.onChange( e, f ) }
                    value={ this.props.values[ field.property ] }
                    valueOptions={ this.props.valueOptions }
                />

            )

        )

    }

}