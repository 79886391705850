import React from 'react'
import { datatron } from '../../util/datatron'
import { documentType } from '../../objectStores/documentType'
import Document from '../../containers/controls/Document'

export default class Documents extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            documentTypes: null,

        }

    }

    componentDidMount() {

        this.loadDocumentTypes()

    }

    loadDocumentTypes() {

        datatron.get( {

            cacheFirst:     true,
            objectStore:    documentType,
            requestOptions: { options: { method: 'GET' }, url: 'api/DocumentType/Get' },
            success:        documentTypes => this.setState( { documentTypes: documentTypes.data.filter( e => e.object === this.props.objectName ) } ),

        } )

    }

    render() {

        return (

            <div className='d-flex'>

                { this.state.documentTypes && this.state.documentTypes.map( ( documentType, i ) =>

                    <Document
                        disabled={ this.props.disabled }
                        document={ this.props.documents && this.props.documents.findLast( e => e.DocumentType.Id === documentType.id ) }
                        documentType={ documentType }
                        key={ i }
                        objectName={ this.props.objectName }
                        onChange={ e => this.props.onChange( e ) }
                        onDelete={ e => this.props.onDelete( e ) }
                        token={ this.props.token }
                        uploadParams={ this.props.uploadParams }
                    />

                ) }

            </div>

        )

    }

}