import React from 'react'
import { withRouter } from 'react-router-dom'
import { translate as _, formatDate } from '@morawadigital/skynet-framework'
import { Col, Dropdown, Placeholder } from 'react-bootstrap'
import Item from './Item'

class PlayerLicense extends React.Component {

    renderOptions() {

        return (

            <>

                <Dropdown.Item as='button' onClick={ () => this.props.onEdit( this.props.item ) }>{ _( 'Bearbeiten' ) }</Dropdown.Item>

                <Dropdown.Item as='button' onClick={ () => this.props.onDelete( this.props.item ) }>{ _( 'Löschen' ) }</Dropdown.Item>

            </>

        )

    }

    render() {

        const item = this.props.item

        return (

            <Item
                colProps={ { xs: 12, sm: 5 }    }
                item=    { item                 }
                name=    { item && item.Number  }
                options= { this.renderOptions() }
            >

                <Col xs='8' sm='4'>

                    { item ?

                        formatDate( item.From ) + '-' + formatDate( item.Till )

                    :

                        <Placeholder animation='wave'><Placeholder xs='12' /></Placeholder>

                    }

                </Col>

                <Col xs='4' sm='3' className='text-end'>

                    { item ?

                        ( item.Status ? item.Status.Name : '' )

                    :

                        <Placeholder animation='wave'><Placeholder xs='12' /></Placeholder>

                    }

                </Col>

            </Item>

        )

    }

}

export default withRouter( PlayerLicense )