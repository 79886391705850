import { ajx, ajxs, translate as _ } from '@morawadigital/skynet-framework'
import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import FieldSet from '../elements/FieldSet'
import { getFieldDefinition } from '../../fieldDefinition'
import { createOptions, getBooleanValue, getSelectValue } from '../../util'

export default class TransferCard extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            fieldDefinition: null,
            saving:          false,
            transferCard:    this.props.transferCard,
            valueOptions:    {},

        }

    }

    componentDidMount() {

        this.loadFieldDefinition()

        this.load()

    }

    componentDidUpdate() {

        if ( ( ! this.props.transferCard && this.state.transferCard ) || ( this.props.transferCard && ! this.state.transferCard ) ) {

            this.setState( { transferCard: this.props.transferCard } )

        }

    }

    load() {

        ajxs( { toggleLoading: true }, [

            { options: { method: 'GET' }, success: e => this.setState( { valueOptions: { ...this.state.valueOptions, federations:          createOptions( e                ) } } ), url: 'api/Federation/Get'         },
            { options: { method: 'GET' }, success: e => this.setState( { valueOptions: { ...this.state.valueOptions, transferCardStatuses: createOptions( e                ) } } ), url: 'api/TransferCardStatus/Get' },
            { options: { method: 'GET' }, success: e => this.setState( { valueOptions: { ...this.state.valueOptions, restrictionTypes:     createOptions( e, 'Id', 'Title' ) } } ), url: 'api/Restriction/Get'        },

        ] )

    }

    loadFieldDefinition() {

        getFieldDefinition( {

            objectName: 'TransferCard',
            success:    fieldDefinition => this.setState( { fieldDefinition } ),

        } )

    }

    save( e ) {

        e.preventDefault()

        this.setState( { saving: true }, () => {

            const data = {

                additionalClubId:    getSelectValue( this.state.transferCard.AdditionalClub     ),
                alreadyCalculated:   getBooleanValue( this.state.transferCard.alreadyCalculated ),
                clubId:              getSelectValue( this.state.transferCard.Club               ),
                from:                this.state.transferCard.From,
                id:                  this.state.transferCard.Id,
                leavingFederationId: getSelectValue( this.state.transferCard.LeavingFederation  ),
                mainFederationId:    getSelectValue( this.state.transferCard.MainFederation     ),
                note:                this.state.transferCard.Note,
                number:              this.state.transferCard.Number,
                playerId:            this.state.transferCard.PlayerId,
                requestDate:         this.state.transferCard.RequestDate,
                restrictionLeagueId: getSelectValue( this.state.transferCard.RestrictionLeague  ),
                restrictionTypeId:   getSelectValue( this.state.transferCard.RestrictionType    ),
                statusId:            getSelectValue( this.state.transferCard.Status             ),
                till:                this.state.transferCard.Till,

            }

            const success = e => {

                this.props.onUpdate( e )
                this.props.onHide()

            }

            ajx( {

                complete: () => this.setState( { saving: false } ),
                single:   true,
                url:      'api/TransferCard/AddOrEdit',

                data, success,

            } )

        } )

    }

    render() {

        return (

            <Modal show={ this.state.transferCard !== null } onHide={ this.props.onHide } backdrop='static'>

                <Form onSubmit={ e => this.save( e ) }>

                    <fieldset disabled={ this.state.saving }>

                        <Modal.Header closeButton>

                            <Modal.Title>{ _( 'Transferkarte' ) }</Modal.Title>

                        </Modal.Header>

                        <Modal.Body>

                            { this.state.transferCard &&

                                <FieldSet
                                    disabled={ this.state.saving }
                                    fieldDefinition={ this.state.fieldDefinition }
                                    onChange={ ( e, f ) => this.setState( { transferCard: { ...this.state.transferCard, [ e ]: f } } ) }
                                    valueOptions={ { ...( this.props.valueOptions || {} ), ...this.state.valueOptions } }
                                    values={ this.state.transferCard }
                                />

                            }

                        </Modal.Body>

                        <Modal.Footer>

                            <Button variant='secondary' onClick={ this.props.onHide }>{ _( 'Abbrechen' ) }</Button>

                            <Button type='submit'>{ _( 'Speichern' ) }</Button>

                        </Modal.Footer>

                    </fieldset>

                </Form>

            </Modal>

        )

    }

}